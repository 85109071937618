import React from "react";
import { Link, graphql } from 'gatsby'
import loadable from "@loadable/component"
import Layout from "../components/layout";
import voca from "voca"
import Seo from '../components/seo';
import { PageLinks } from "../common/site/page-static-links";
import LocRatingMap from '../components/map/loc-rating-map';
import { MapMarkerInfoBox } from '../components/map/map-marker'
import SimilarProperties from "../components/SimilarProperties/SimilarProperties";
import PropertyCta from "../components/PropertyCta/PropertyCta";
import { numberFormat } from "../components/PropertyCalculator/utils";
import { homeValuation, instantValuation } from "../site/urls";
import PropertyStructuredData from "../components/Seo/PropertyStructuredData";
const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const PropertyDetailDesc = loadable(() => import("../components/PropertyDetailDesc/PropertyDetailDesc"));
const PropertyAvailability = loadable(() => import("../components/NewHomesAvailability/NewHomesAvailability"));
const PropertyBanner = loadable(() => import("../components/PropertyBanner/PropertyBanner"));
// const PropertyDescription = loadable(() => import("../components/PropertyDescription/PropertyDescription"));
// const FeaturedProperties = loadable(() => import("../components/FeaturedProperties/SimilarProperties"));

const offplanDetailsTemplate = ({ data, children }) => {
  const PageData = data?.strapiProperty
  const AvailableProperty = data?.allStrapiProperty?.edges
  var imagename = "property.images.details";
  let processedImages = JSON.stringify({});
  if (data?.strapiProperty.imagetransforms?.images_Transforms) {
    processedImages = data?.strapiProperty?.imagetransforms?.images_Transforms;
  }

  let propertyDetailImg = [];
  //if((data?.strapiProperty?.images).length > 0) {
  for (let i = 0; i < data?.strapiProperty?.images?.strapi_json_value.length; i++) {
    if (data.strapiProperty?.images?.strapi_json_value[i].url) {
      propertyDetailImg.push(data.strapiProperty?.images?.strapi_json_value[i].url);
    }
  }
  //}
  // we could server resized version instead of original one to avoid load time
  //features = []
  //if(data.strapiProperty?.accommodation_summary?.strapi_json_value)
  let features = []//data.strapiProperty?.accommodation_summary?.strapi_json_value;

  const url = typeof window !== 'undefined' ? window.location.href : ''
  let mapItems = [];
  let mapItem = {};
  mapItem['lat'] = PageData?.latitude
  mapItem['lng'] = PageData?.longitude
  const mapMarkerImageShowFlag = 0;
  let mapItemHtml = MapMarkerInfoBox("test", mapMarkerImageShowFlag);
  mapItem['html'] = mapItemHtml;
  mapItems.push(mapItem);
  const sidebarData = data?.strapiGlobalMenu

  let newHomesDetailsObject = {
    searchType: PageData?.status,
    area: PageData.area,
    postcode: PageData?.address?.postcode
  }


  return (
    <Layout className="property-details-page-layout" popularSearch="newhomes-details" newHomesDetailsObject={newHomesDetailsObject}>
      <div className="layout-padding-top propety-details-page">
        <div className="bottom-right-image-home">
          <div className="hover-block">
            <div className="block-elements">
              {/* <Link to={instantValuation}>Instant Online Valuation</Link>
              <div className="vertical-line"></div> */}
              <Link to={homeValuation}>Home Visit Valuation</Link>
            </div>
          </div>
          <div className="banner-img"></div>
        </div>
        <div>
          <BreadcrumbModule department={data.strapiProperty?.department} alias={data.strapiProperty?.search_type} type="property-details-page" pagename={data.strapiProperty?.display_address} />
        </div>
        <PropertyBanner {...data.strapiProperty} propImg={propertyDetailImg} processedImages={processedImages} imagename={imagename} crm_id={data?.strapiProperty?.crm_id} prop_id={data?.strapiProperty?.strapi_id} />
        <PropertyDetailDesc
          sidebarData={sidebarData}
          data={PageData}
          {...PageData} />
        <PropertyAvailability data={AvailableProperty}/>
        {PageData?.latitude && PageData?.longitude ?
          <div className="area-guide-map">
            <LocRatingMap data={mapItems} />
          </div>
          : null
        }
        <SimilarProperties prop_id={data?.strapiProperty?.strapi_id} title={`Similar Listings`} status={data.strapiProperty.status} department={data.strapiProperty.department} />
        <PropertyCta shareurl={url} data={data?.strapiProperty}
          crm_negotiator_id={data.strapiProperty?.crm_negotiator_id} />


      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($crm_id: String) {
    strapiProperty(crm_id: {eq: $crm_id}) {
        title
        crm_id
        display_address
        area
        department
        address {
          display_address
          postcode
        }
        latitude
        floorarea_min
        floorarea_type
        longitude
        status
        long_description{
            data {
                long_description
            }
        }
        virtual_tour{
           url
          }
          brochure {
            strapi_json_value {
              url
            }
          }
        extra {
            tenure
            tenure_type
            remaining_lease
            council_tax_band
            service_charges
            ground_rent  
            available_from
            tenancy_type
            furnished  
            price_monthly
            price_text
            energyRate
            garden_info {
              para {
                value
              }
            }
        }
        special {
            strapi_json_value
        }
        parking {
          strapi_json_value
      }
        price
        images {
            strapi_json_value {
                srcUrl
                url
            }
          }
        description {
            data {
              description
            }
        }
        building {
            strapi_json_value
        }
        bedroom
        bathroom
        imagetransforms {
            images_Transforms
        }
        price_qualifier
        epc {
            strapi_json_value {
              srcUrl
            }
        }
        floorplan {
            strapi_json_value {
              srcUrl
            }
        }
        reception
        crm_negotiator_id{
          email
          phone
        }
        search_type
        strapi_id
        garden
        
    }

    strapiGlobalMenu {
      strapi_id
      imagetransforms {
          sidebar_image_Transforms
      }
      sales_team {
        title
        content {
          data {
            content
          }
        }
        image {
          url
        }
        add_link {
          label
          link {
            slug
            strapi_parent {
              slug
              strapi_parent {
                slug
              }
            }
          }
        }
      }
      lettings_team {
        title
        content {
          data {
            content
          }
        }
        image {
          url
        }
        add_link {
          label
          link {
            slug
            strapi_parent {
              slug
              strapi_parent {
                slug
              }
            }
          }
        }
      }
      news_sidebar {
          title
          content {
            data {
              content
            }
          }
          image {
            url
          }
          add_link {
            label
            link {
              slug
              strapi_parent {
                slug
                strapi_parent {
                  slug
                }
              }
            }
          }
        }
    }

    allStrapiProperty(filter: {master_id: {eq: $crm_id}}) {
      totalCount
      edges {
        node {
          master_id
          crm_id
          department
          building {
            strapi_json_value
          }
          bathroom
          bedroom
          floorarea_min
          floorarea_type
          price
          price_qualifier
          slug
          search_type
          status
          brochure {
            strapi_json_value {
              url
            }
          }
        }
      }
    }

  }
`

export const Head = ({ data }) => {
  const propertyData = data?.strapiProperty

  const building = propertyData?.building?.strapi_json_value?.length > 0 ? propertyData?.building?.strapi_json_value[0] : ""
  const action = propertyData?.search_type === "sales" ? "for sale" : "for rent"

  const title = `${voca.capitalize(building)} ${action} with ${propertyData?.bedroom} in ${propertyData?.display_address} at £ ${numberFormat(propertyData?.price)}`
  const description = `Find the details of ${building} ${action} with ${propertyData?.bedroom} bedrooms in ${propertyData?.display_address} at £ ${numberFormat(propertyData?.price)}. Request a viewing to get assistance in knowing more about this ${building}.`
  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <>
    <Seo title={title} description={description} />
    <PropertyStructuredData
    property={propertyData}
    metadesc={description}
    pageurl={pageurl}
  />
  </>
  )
}

export default offplanDetailsTemplate